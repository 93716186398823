import React from 'react';
import {
  Modal as ChModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  IconButton,
  Heading,
  Text,
  ModalFooter,
  Box,
  HStack
} from '@chakra-ui/react';

import { MdArrowBack } from 'react-icons/md';

const Modal = ({
  isOpen,
  onClose,
  isCentered,
  scrollBehavior,
  children,
  size,
  withCloseButton,
  headerTools,
  title,
  description,
  initialFocusRef,
  autoFocus,
  bodyProps,
  motionPreset,
  footer
}) => {
  return (
    <ChModal
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      isCentered={isCentered}
      initialFocusRef={initialFocusRef}
      autoFocus={autoFocus}
      motionPreset={motionPreset}
      scrollBehavior={scrollBehavior}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent p="0" rounded="2xl">
        {(withCloseButton || headerTools) && (
          <ModalHeader boxShadow="sm" px="2" py="2" as={HStack}>
            {withCloseButton && (
              <IconButton
                tabIndex="-1"
                onClick={onClose}
                variant="ghost"
                icon={<MdArrowBack size="1.5em" />}
              />
            )}
            <Box flex="1" />
            {headerTools}
          </ModalHeader>
        )}
        <ModalBody py={4} px={4} {...bodyProps}>
          {title && (
            <Heading as="h3" size="lg" lineHeight={2} fontWeight="black">
              {title}
            </Heading>
          )}
          {description && <Text size="md">{description}</Text>}
          {children}
        </ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChModal>
  );
};

Modal.defaultProps = {
  motionPreset: 'none'
};

export default Modal;
